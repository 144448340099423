import React, { ReactElement, useEffect, useState } from 'react';
import { IPageData, IPageProps } from '@Types/ContentfulTypes';
import { usePageTheme, useApp } from '@Context/AppContext';
import '@GraphQL/fragments';
import AdvanceInquirySubmittedView from '@Src/components/Advance/Inquiry/Submitted';
import AdvanceInquiryLayout from '@Src/components/Advance/Inquiry/Layout';
import ContentLayout from '@Src/components/sitestructure/ContentLayout';
import Text from '@Src/components/Text';

export interface Props extends IPageProps {
    data: {
        contentfulPageTypePitch: IPageData;
    };
    locale?: string
    path?: string
    advanceInquiryId: string
}

const AdvanceInterestSubmittedPage = ({ data, advanceInquiryId, location, pageContext, transitionStatus }: Props): ReactElement => {
  //const { title, seoSettings, content } = data.contentfulPageTypePitch;
  const title = '', seoSettings = 'label pitch'
  let locale =  'en-US'

  const { setTheme: setPageTheme } = usePageTheme();
  const { mode, pageType, setPageType, setPageTitle } = useApp();
  const [localTransStatus, setLocalTransStatus] = useState<string>(transitionStatus);
  const [content, setContent] = useState(null)
  const language = locale.split(/-/)[0]
  useEffect(() => {
      if (pageType === 'index' && transitionStatus == 'exiting') {
          setLocalTransStatus(transitionStatus + 'Index');
      } else {
          setLocalTransStatus(transitionStatus);
      }

      if (transitionStatus == 'entering' || transitionStatus == 'entered') {
          setPageTheme(mode == 'yellow' ? mode : mode == 'day' ? 'yellow' : 'night');
          setPageTitle(title);
          setPageType(pageContext.type);
      }
  }, [transitionStatus]);


  useEffect(() => {
    if (transitionStatus == 'entered') {
      setPageTheme(mode == 'day' ? 'yellow' : 'dark');
    }
  }, [mode]); 

  return (
    <ContentLayout fullWidth={true} type="signup" transitionState={localTransStatus}>
      <div style={{textAlign: 'center', padding: 20}}>
        <Text theme={mode} heading01={`Congratulations!`} />
        <Text theme={mode} htmlText={`Thanks for your advance inquiry!`} />
        <Text theme={mode} htmlText={`Keep up the good work! In a few minutes we will send you a first estimate to your e-mail very soon!`} />
      </div>
    </ContentLayout>
  )
}

export default AdvanceInterestSubmittedPage